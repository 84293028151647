import firebase from 'firebase';

var firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? {
        apiKey: 'AIzaSyBFaLEEaeQMDgkDJ8g9iB6Q6gfBxHT2adI',
        authDomain: 'balancesoft-3df4c.firebaseapp.com',
        databaseURL: 'https://balancesoft-3df4c-default-rtdb.firebaseio.com',
        projectId: 'balancesoft-3df4c',
        storageBucket: 'balancesoft-3df4c.appspot.com',
        messagingSenderId: '111711161392',
        appId: '1:111711161392:web:5687d020091245daf2f1f5',
        measurementId: 'G-T7DQL0M23P',
      }
    : {
        apiKey: 'AIzaSyBxXUN1gCJ788UmUbBzscurb25JXjln914',
        authDomain: 'balancesoft-prod.firebaseapp.com',
        databaseURL: 'https://balancesoft-prod-default-rtdb.firebaseio.com',
        projectId: 'balancesoft-prod',
        storageBucket: 'balancesoft-prod.appspot.com',
        messagingSenderId: '328403855472',
        appId: '1:328403855472:web:d0d139180ddb0566efacb5',
        measurementId: 'G-4XL69S2P60',
      };

firebase.initializeApp(firebaseConfig);
export default firebase;
